import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-mdx';
import ImageContext from '../components/ImageContext';
import { LiveMessage } from 'react-aria-live';
import { css } from '@emotion/core';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const itemArticleStyle = theme => css`
  border-bottom: 1px solid ${theme.cDark};
  margin-bottom: 2em;

  .gatsby-image-wrapper {
    border-radius: 0.5em;
    margin-top: 3em;
    margin-bottom: 3em;
    box-shadow: 0 0.2em 1em rgba(59, 43, 91, 0.7);
  }

  ul,
  ol {
    list-style: initial;
    margin-left: 1em;
    font-size: 1.2em;
    padding-top: 0.3em;

    li {
      line-height: 1.5em;
    }
  }

  blockquote {
    border-left: 0.3em solid ${theme.cDark};
    padding-left: 0.5em;
    font-style: italic;
  }

  p {
    font-size: 1.2em;
    line-height: 1.5em;
  }

  h2 {
    font-size: 2.6em;
    margin-bottom: 0;

    ${theme.mqMax['medium']} {
      font-size: 2.3em;
    }
  }

  h3 {
    font-size: 2.1em;
  }

  h4 {
    font-size: 1.9em;
  }

  h5 {
    font-size: 1.7em;
  }

  h6 {
    font-size: 1.5em;
  }

  .publish {
    font-weight: bold;
    font-style: italic;
    font-size: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid ${theme.cBlack};
    span {
      font-weight: normal;
    }
  }
`;

const ItemTemplate = ({ data }) => {
  const { item } = data;
  const siteTitle = data.site.siteMetadata.title;

  const BreadCrumb = () => (
    <nav aria-label="breadcrumb" className="breadcrumb">
      <Link to="/">Home</Link>
      <span aria-hidden="true">→</span>
      <Link to={`/${item.category.mainCategory.slug}`}>
        {item.category.mainCategory.title}
      </Link>
      <span aria-hidden="true">→</span>
      <Link to={`/${item.category.mainCategory.slug}/${item.category.slug}`}>
        {item.category.title}
      </Link>
    </nav>
  );

  return (
    <Layout title={siteTitle}>
      <LiveMessage
        message={`Navigated to ${item.title}`}
        aria-live="assertive"
      />
      <Helmet>
        <title>{`Jurassic Park Militaria | ${item.title}`}</title>
      </Helmet>
      <BreadCrumb />
      <article css={itemArticleStyle}>
        <h2>{item.title}</h2>
        <p className="publish">
          <span>Published on </span>
          {item.datePublished}
        </p>
        <ImageContext.Provider value={item.images}>
          <MDXRenderer>{item.contentNode.childMdx.code.body}</MDXRenderer>
        </ImageContext.Provider>
      </article>
      <BreadCrumb />
    </Layout>
  );
};

export default ItemTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    item: datoCmsItem(slug: { eq: $slug }) {
      id
      title
      slug
      datePublished(formatString: "MMMM DD, YYYY")
      images {
        url
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      category {
        title
        slug
        mainCategory {
          title
          slug
        }
      }
      contentNode {
        id
        childMdx {
          code {
            body
          }
        }
      }
    }
  }
`;
